@import "./scss/variables";

@import "../node_modules/bootstrap/scss/bootstrap";

/* ---------------------------------------------------
    GENERAL STYLES
----------------------------------------------------- */

html {
  font-size: 14px;
}

body {
  overflow-x: hidden;
  color: theme-color("primary");
}

.btn {
  padding-left: 24px;
  padding-right: 24px;
}

.btn-outline-primary {
  border-color: theme-color("mediumgray");
  border-width: 1px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

table {
  tr {
    cursor: pointer;
  }
}

.text-link {
  color: theme-color("blue");
  text-transform: uppercase;
  width: max-content;
}

.text-link:hover {
  color: theme-color("darkgray");
  cursor: pointer;
}

.text-link>i {
  svg path {
    fill: theme-color("blue");

  }
}

.text-link:hover i {
  cursor: pointer;

  svg path {
    fill: theme-color("darkgray");
  }
}

button.text-link {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: 400;
  border-radius: unset;
  min-width: max-content !important;
}

button.text-link:hover {
  background-color: transparent;
}

.profile-image {
  border-radius: 50%;
  background-color: theme-color("blue");
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.card {
  margin-top: 20px;
  background-color: transparent;
  border: none;

  & .card-header {
    background-color: transparent;
    font-size: 18px;
    border: none;
  }

  & .no-shadow {
    box-shadow: none;
  }
}

.App-header {
  margin-top: 33vh;
}

.green {
  color: map-get($theme-colors, "green");
}

.darkblue {
  color: map-get($theme-colors, "darkblue");
}

.clear-both {
  clear: both;
}

/* ---------------------------------------------------
    FORM STYLES
----------------------------------------------------- */

input.form-control {
  border-width: 0 0 $input-border-width 0;
  padding-left: 26px;
  color: theme-color("primary");
}

input[type="search"] {
  padding-left: 0px;
  padding-right: 20px;
  min-width: 250px;
}

select.form-control {
  border-width: 0 0 $input-border-width 0;
  padding-left: 0px;
}

.form-icon {
  position: absolute;
  margin-top: 7px;
  z-index: 999;


  i {
    display: flex;
  }

  svg path {

    fill: theme-color("mediumgray");
  }

}

.form-icon-textarea {
  transform: translateX(8px);
}

textarea {
  padding-left: 2rem !important;
}

.form-group {
  margin-bottom: 2.5em;
}

.form-check-label {
  margin-left: 20px;
  margin-bottom: 10px;
}

// LABELS
label.floating-label {
  position: absolute;
  transition: all 350ms ease-in;
  color: map-get($theme-colors, "mediumgray");
  transform: translate3d(25px, -28px, 0);
  pointer-events: none;
}

label.floating-label.filter {
  transform: translate3d(5px, -28px, 0);
  color: map-get($theme-colors, "primary");
}

label.floating-label.dropdown {
  font-size: .8em;
  transform: translate3d(25px, -52px, 0);
  display: inline-block;
}

label.floating-label.filter-dropdown,
.form-control:focus+.floating-label.filter-dropdown {
  font-size: .8em;
  transform: translate3d(5px, -52px, 0);
  color: map-get($theme-colors, "mediumgray");
}

.form-control:focus+.floating-label {
  font-size: .8em;
  transform: translate3d(25px, -52px, 0);
  display: inline-block;
}

input:not(.show-placeholder)::placeholder {
  color: transparent;
}

.show-placeholder input.rbt-input-main::placeholder {
  color: inherit;
}

// if field has data in it, show the label above the input
input:not(:placeholder-shown)+.floating-label {
  font-size: .8em;
  transform: translate3d(25px, -52px, 0);
}

// custom for CC email on Bank Contact Form
label.floating-label.ccemail {
  transform: translate3d(25px, 7px, 0);
  transition: all 350ms ease-in;
}

.form-control:focus+.floating-label.ccemail {
  font-size: .8em;
  transform: translate3d(25px, -17px, 0);
  display: inline-block;
}

input:not(:placeholder-shown)+.floating-label.ccemail {
  font-size: .8em;
  transform: translate3d(25px, -17px, 0);
}


// custom for search input
label.floating-label.search {
  transform: translate3d(-190px, 10px, 0);
  transition: all 350ms ease-in;
}

.form-control:focus+.floating-label.search {
  font-size: .8em;
  transform: translate3d(-205px, -18px, 0);
  display: inline-block;
}

// custom for filter label
.form-control:focus+.floating-label.filter {
  font-size: .8em;
  transform: translate3d(5px, -52px, 0);
  color: map-get($theme-colors, "mediumgray");
}

input:not(:placeholder-shown)+.floating-label.filter {
  font-size: .8em;
  transform: translate3d(5px, -52px, 0);
  color: map-get($theme-colors, "mediumgray");
}

// close button on typeaheads
.close.rbt-close {
  position: absolute;
  top: 6px;
  right: 0;
  z-index: 9999;
}

/* ---------------------------------------------------
    TABLE STYLES
----------------------------------------------------- */

.table,
.table tr,
.table thead,
.table td,
.table th {
  border: none !important;
}

.table thead {
  text-transform: uppercase;
  color: theme-color("darkblue");
}

.table th,
.table td {
  padding: 10px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: normal;
}

.table-responsive .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.react-bs-table-sizePerPage-dropdown {
  .dropdown-toggle {
    border-color: #2F353A;
    background: #2F353A !important;
    color: #fff !important;
  }
}

.edit-delete-column {
  line-height: normal;
  cursor: pointer;
  width: 50px;

  i {
    margin: 0 5px;
  }

}

.pagination {
  justify-content: flex-end;

  .page-link {
    border: none;
    background-color: transparent;
    border-radius: 0 !important;
    color: theme-color("mediumgray");
    padding-bottom: 0.25rem;

    &:hover {
      background-color: transparent;
    }

  }

  .page-item.active .page-link {
    color: theme-color("primary");
    background-color: transparent;
    border-bottom: 1px solid theme-color("primary");
  }

  .page-item[title~=page] .page-link {
    color: theme-color("primary");
  }

}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

main {
  height: calc(100vh - #{$header-height});
  display: flex;
  align-items: stretch;
}

main.full-screen {
  height: 100vh;
  display: block;
}

.content {
  background: transparent linear-gradient(180deg, #ffffff 0%, #eaeaea 100%) 0% 0% no-repeat padding-box;
  overflow-y: auto;

  .inner-content {
    min-height: calc(100% - #{$footer-height});
  }

  & .footer {
    border-top: 1px solid theme-color("lightgray");
    font-size: 12px;
    height: $footer-height;
   
  }
}

.content.report-page {
  background: #eaeaea;
}

.print-button {
  font-size: 30px;
  cursor: pointer;

  i {
    float: right;
  }
}

.NoteForm {
  i {
    padding-left: 10px;
  }
}

.invite-form {
  input:not(.form-control-file),
  select {
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    border-radius: 0.25rem;
  }
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 0.25em;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }

  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }

  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
}

.home-page {
  text-align: center;
}

/* ---------------------------------------------------
    LOGIN STYLES
----------------------------------------------------- */

@import '../variables';

li a.js-help-link {
  display: none;
}


.login {
  position: relative;
  background: map-get($theme-colors, "blue");
  overflow: auto;
  border: 1px solid #707070;
  height: 100vh;
}

.login-footer {
  position: absolute;
  left: 24px;
  bottom: 22px;
  font-size: 16px;
  color: #fff;
}

.okta-wrapper {
  position: absolute;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .auth-container {
    display: block;
  }

  @media only screen and (max-device-width : 480px) {
    .auth-container {
      border-radius: 10px !important;
      margin-top: 115px !important;
      box-shadow: 0px 0px 20px 5px #666 !important;
    }
  }

  @media only screen and (min-device-width : 640px) {
    .auth-container {
      border-radius: 10px !important;
      margin-top: 225px !important;
      box-shadow: 0px 0px 20px 5px #666 !important;
    }
  }


  .auth-content {
    padding-top: 0px !important;
  }

  h2 {
    text-transform: uppercase;
  }

  .button.button-primary {
    border-radius: 40px !important;
    width: 180px !important;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    font-weight: bold !important;
    text-align: center;
    border: 2px solid transparent !important;
    padding: 5px 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    height: auto !important;
  }

  #okta-sign-in .focused-input{
    box-shadow: none !important;
  }

  #okta-sign-in .auth-header {
    border-bottom: 0 !important;
  }

  .okta-form-title {
    font-size: 26px !important;
    text-transform: uppercase !important;
    margin-bottom: 1em !important;
  }

  .okta-form-input-field {
    border-radius: 40px !important;

    input {
      width: 90% !important;
    }

  }

  .okta-form-label,
  .o-form-input-name-remember,
  .o-form-has-errors {
    text-align: left;
  }

  .o-form-fieldset-container {
    text-align: center;
  }
}


.login-support {

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .okta-wrapper {
    position: relative !important;
    left: auto !important;
    top: auto !important;
    width: auto !important;
    height: auto !important;
  }

  .okta-wrapper .auth-container {
    margin-top: 0px !important;
  }

  & .okta-wrapper #okta-sign-in .auth-header.login-support-header {
    padding: 30px 90px 30px;
  }

  & #okta-sign-in .auth-content {
    padding-bottom: 0px;

  }

  .login-support-footer {
    margin: 25px 0px !important;
  }

  input.form-control {
    border-width: 0 0 $input-border-width 0 !important;
    padding-left: 26px !important;
    color: map-get($theme-colors, "primary") !important;
  }

  select.form-control {
    border-width: 0 0 $input-border-width 0 !important;
    padding-left: 0px !important;
  }

  .form-icon {
    position: absolute !important;
    margin-top: 10px !important;
    color: map-get($theme-colors, "primary") !important;
  }

  .form-icon-textarea {
    transform: translateX(8px) !important;
  }

  textarea {
    padding-left: 2rem !important;
  }

  .form-group {
    margin-bottom: 2em !important;
  }

  .btn {
    background: map-get($theme-colors, "blue");
    border-color: map-get($theme-colors, "blue");
    box-shadow: 0 1px 0 rgba(0, 0, 0, .15), inset 0 1px 0 0 hsla(0, 0%, 100%, .1);
  }

  .support-form form {
    justify-content: center;
  }
}

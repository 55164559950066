@import '../../variables';

/* ---------------------------------------------------
    PROFILE MENU STYLE
----------------------------------------------------- */

.profile-menu-mask {
  height: calc(100vh - #{$header-height});
  position: absolute;
  width: 100%;
  z-index: 9999;
  background: transparent;
  display: none;
  transition: all 0.5s;

  &.is-open {
    display: block;
    transition: all 0.5s;

    &::before {
      content: '';
      width: 15px;
      height: 15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      float: right;
      border-bottom: 15px solid #fff;
      margin-right: 2px;
    }
  }

}

.profile-menu-container {
  min-width: $profile-menu-width;
  max-width: $profile-menu-width;
  background-color: #fff;
  transition: all 0.5s;
  position: absolute;
  z-index: 99999;
  top: 0px;
  display: none;
  margin-left: calc(100% - #{$profile-menu-width});

  &.is-open {
    transition: 0.5s;
    margin-top: 65px;
    display: block;

  }

  .profile-menu-header .close {
    width: 30px;
    height: 30px;

    &:hover {
      cursor: pointer;
    }

  }

  .profile-menu {

    .nav-item {

      .form-control {
        padding: 0.5rem 1rem;
        border: none;
      }

      :hover {
        background: map-get($theme-colors, "lightgray");
      }
    }
  }
}

.bulk-drag-n-drop-area{
    background-color: #fff;
    border: 2px #BDC4C7 dashed;
    border-radius: 5px;
    display: flex;
}
.bulk-drag-drop-area{
    text-align: center;
    flex: 1;
}
.bulk-drag-drop-section{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bulk-drag-drop-upload-btn-container{
    margin: 10px;
}
.bulk-drag-drop-text-1{
    font-size: 12px;
    color:#68758B
}
.bulk-drag-drop-text-2{
    color:#3D515A;
}

.bulk-upload-section {
    border-style: solid;
    border-width: 1px 0;
    border-color: #BDC4C7;
}

.bulk-upload-section > div {
    width: 100%;
    padding: 10px 0;
}
.bulk-invite-container {
    border: 1px solid #959595;
    background-color: white;
}
.bulk-checkbox-section {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
}

.bulk-checkbox-section > div {
    margin: 0 10px;
}
.bulk-checkbox {
    padding: 10px;
    border: 1px solid #BDC4C7;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.bulk-checkbox label {
    margin: 0;
}

.bulk-checkbox input {
    margin-right: 10px;
}

.bulk-upload-btn{
    background-color: #303340;
    color: white;
    border-radius: 30px;
    border-color:#303340;
    font: 1em;
    font-weight: 600;
    text-transform: none;   
}
.bulk-upload-btn:hover{
    background-color: #A4A4A4;
    border-color:#303340;
    
}
.gray-bg{
    background-color: #DBE1E2 !important;
    color: #3D515A;
    height: 50px;
    font-weight: 500;
}
.table-container{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.table-display-name{
    font-weight:bold;
    word-wrap: break-word;

}
.table-field-text{
    font-family: Open Sans;
    color: #3D515A;

}
.search-invite-datebutton-container{
    position: absolute;
    top: 15px;
    right: 15px;
}
.date-picker-button{
    border: 1px solid #252525 !important ;
    background-color: #fff !important;
    border-radius: 20px !important;
}
.date-picker-active{
    background-color: #303340 !important;
    color: #FFFFFF !important;
    border-radius: 20px !important;
}
.purchase-date-calender-container{
    margin-right:40px;
    margin-top: -60px;
}
.date-picker-select-btn-container{
    position: absolute;
    bottom: 0;
    right: 10px;
    
    /* margin: 10px; */
}
.date-picker-select-btn{
    
    color: rgb(0, 128, 255);
    /* margin: 10px; */
}

.color-dot-row-invitation-detail{
    flex: '0 0 auto';
    width:  "50%"; 
    height: '15px';
    width: '15px'; 
    border-radius: '50%';
    display: 'inline-block';
}
@import '../variables';
@import "../../../node_modules/bootstrap/scss/bootstrap";

.vehicle-documents {
  text-align: left;
  h2 {
    padding: 1rem;
  }

  .loader {
    background: gray;
  }

  .search-vin {
    max-width: 20rem;
    padding: 1rem;
    #vin-search {
      padding-left: 0;
    }
    .icon-btn {
      padding: 0.5rem;
    }
  }

  .drag-n-drop-area {
    width: 25rem;
    height: 10rem;
    background: #ccc;
    border: dashed 0.125rem black;
    cursor: pointer;
    border-radius: 1rem;
    padding: 1.5rem;
  }

  .staged-uploads {
    padding: 1.5rem;
    width: 25rem;
    margin-top: 1rem;
    background: rgba(theme-color("blue"), 0.5);
  }
}

@import "../../../scss/variables";

.loader {
    height: 75vh;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;

    & i {
        color: map-get($theme-colors, "blue");
        font-size: 36px;
        animation-name: spin;
        animation-delay: .5s;
        animation-duration: 500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }


    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
      }


}
  